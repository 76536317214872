* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans";
}

*::-webkit-scrollbar {
  background-color: #5bc1a5;
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 15px;
}
